import axios, { AxiosError } from "axios";

const exportData = (
  obligedId: string,
  token: string,
  error: (message: string) => void,
  filter?: string,
  status?: string,
  pnceeCsv = false,
  projectId?: string,
  isVehicleFleet = false,
  tableSummary = false,
): Promise<void> =>
  axios
    .get(
      `${process.env.REACT_APP_EXPORT_API_URL}${
        process.env.REACT_APP_EXPORT_API_URL?.indexOf("?") === -1 ? "?" : "&"
      }obliged=${obligedId}${filter ? `&filter=${filter}` : ""}${status ? `&status=${status}` : ""}${
        pnceeCsv ? "&pnceeCsv=true" : ""
      }${projectId ? `&projectId=${projectId}` : ""}${isVehicleFleet ? "&isVehicleFleet=true" : ""}${
        tableSummary ? "&tableSummary=true" : ""
      }`,
      {
        method: "GET",
        responseType: "blob",
        headers: {
          authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        pnceeCsv
          ? `reportingPNCEE_${obligedId || `project_${projectId}`}.csv`
          : tableSummary
          ? `tableau_recapitulatif.xlsx`
          : `reporting_${obligedId}.xlsx`,
      );
      link.setAttribute("style", "display: none");
      document.body.appendChild(link);
      link.click();
    })
    .catch((reason) => {
      const axiosError = reason as AxiosError;
      new Blob([axiosError.response?.data]).text().then((text) => error(text));
    });

export default exportData;
