import { NavigateNext } from "@mui/icons-material";
import { List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { FC, ReactNode } from "react";

export interface GdListItem {
  icon?: ReactNode;
  content: ReactNode;
  secondaryContent?: ReactNode;
}

interface GdListProps {
  items: GdListItem[];
  dense?: boolean;
  fontSize?: "normal" | "big";
}

const GdList: FC<GdListProps> = ({ items, dense, fontSize }) => (
  <List dense={dense}>
    {items.map((i) => (
      <ListItem
        key={JSON.stringify(i.content)}
        sx={{
          alignItems: i.secondaryContent ? "flex-start" : "center",
          padding: "2px",
        }}>
        <ListItemIcon sx={{ paddingTop: i.secondaryContent ? "10px" : 0 }}>
          {i.icon ? i.icon : i?.content === "" ? undefined : <NavigateNext />}
        </ListItemIcon>
        <ListItemText
          primary={fontSize === "big" ? <Typography variant="h6">{i.content}</Typography> : i.content}
          secondary={
            fontSize === "big" ? <Typography variant="h6">{i.secondaryContent}</Typography> : i.secondaryContent
          }
        />
      </ListItem>
    ))}
  </List>
);

GdList.defaultProps = { dense: false, fontSize: "normal" };

export default GdList;
