import { Stack, Typography } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ProjectsContext } from "../../data/contexts/ProjectsContext";
import { operationsToOperationsInput } from "../../data/dataConvertors";
import { Operation, OperationInput, Project, Sector } from "../../data/generated/graphql";
import GdButton from "../../utils/GdButton";
import { Lvl, log } from "../../utils/log";
import { dontTouchComputationOnProject, projectHaveFinalOperations } from "../ProjectHelper";
import OperationsViewer from "../form/OperationsViewer";
import FormEditModal from "./FormEditModal";
import { getNewIdToFinalOperation } from "../form/FormHelper";

interface FinalOperationsProps {
  project: Project;
}

const FinalOperations: FC<FinalOperationsProps> = ({ project }) => {
  const { t } = useTranslation("project");
  const { updateProject } = useContext(ProjectsContext);
  const [isAdjustingOperations, setIsAdjustingOperations] = useState(false);
  const [editedOperation, setEditedOperation] = useState<OperationInput | null>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [operationIsRemoving, setOperationIsRemoving] = useState<string | undefined>(undefined);
  const dontTouchOperations = dontTouchComputationOnProject(project);
  const [newFinalOperations, setNewFinalOperations] = useState<Operation[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setNewFinalOperations((project.finalOperations as Operation[]) || []);
  }, [project.finalOperations]);

  const openModal = (o?: OperationInput): void => {
    if (o) setEditedOperation(o);
    setModalOpen(true);
  };
  const closeModal = (): void => {
    setModalOpen(false);
    setEditedOperation(null);
  };

  const removeOperation = async (operationToRemove: OperationInput, newIsDeleted: boolean): Promise<void> => {
    setOperationIsRemoving(operationToRemove.id);
    const tempFinaleOperations = project.finalOperations?.map((o) =>
      o?.id === operationToRemove.id ? { ...o, isDeleted: newIsDeleted } : o,
    );
    const result = await updateProject({
      id: project.id,
      finalOperations: operationsToOperationsInput(tempFinaleOperations as Operation[]),
    });
    if (!result) {
      log("Error while updating project", Lvl.ERROR);
      enqueueSnackbar(t("global:errorWhileUpdating"), { variant: "error" });
    } else {
      enqueueSnackbar(t("global:updateSuccess"), { variant: "success" });
      setNewFinalOperations(tempFinaleOperations as Operation[]);
      setOperationIsRemoving(undefined);
    }
    setOperationIsRemoving(undefined);
  };
  const updateOperation = async (operationToUpdate: Operation): Promise<void> => {
    setIsLoading(true);
    const tempFinalOperations = newFinalOperations.map((o) => (o.id === operationToUpdate.id ? operationToUpdate : o));
    const operationIndex = newFinalOperations?.findIndex((o) => o?.id === operationToUpdate.id);
    if (operationIndex === -1)
      tempFinalOperations.push({ ...operationToUpdate, id: getNewIdToFinalOperation(newFinalOperations) });
    const result = await updateProject({
      id: project.id,
      finalOperations: operationsToOperationsInput(tempFinalOperations as Operation[]),
    });

    if (!result) {
      log("Error while updating project", Lvl.ERROR);
      enqueueSnackbar(t("global:errorWhileUpdating"), { variant: "error" });
    } else {
      enqueueSnackbar(t("global:updateSuccess"), { variant: "success" });
      setNewFinalOperations(tempFinalOperations);
      setIsLoading(false);
      closeModal();
    }
    closeModal();
    setIsLoading(false);
  };

  const adjustOperations = async (isDelete = false): Promise<void> => {
    setIsAdjustingOperations(true);
    const tempFinalOperations = isDelete ? null : operationsToOperationsInput(project.operations as Operation[]);
    const result = await updateProject({
      id: project.id,
      finalOperations: tempFinalOperations,
    });
    if (!result) {
      log("Error while updating project", Lvl.ERROR);
      enqueueSnackbar(t("global:errorWhileUpdating"), { variant: "error" });
    } else {
      enqueueSnackbar(t("global:updateSuccess"), { variant: "success" });
      setNewFinalOperations(tempFinalOperations as Operation[]);
      setIsAdjustingOperations(false);
    }
    setIsAdjustingOperations(false);
  };
  const replaceOperations = async (newOperations: OperationInput[]): Promise<void> => {
    const result = await updateProject({ id: project.id, finalOperations: newOperations });

    if (!result) {
      log("Error while updating project", Lvl.ERROR);
      enqueueSnackbar(t("global:errorWhileUpdating"), { variant: "error" });
    } else {
      enqueueSnackbar(t("global:updateSuccess"), { variant: "success" });
    }
  };

  const onClickToAddOperation = (): void => {
    const newEmptyOperation = {
      id: getNewIdToFinalOperation(newFinalOperations),
      formId: "",
      data: [],
      isReplacement: false,
      isCpeScoped: false,
    };
    setModalOpen(true);
    setEditedOperation(newEmptyOperation);
  };
  return (
    <>
      <div className="row margin-top space-between">
        <Typography variant="h6">{t("finalOperations.title")}</Typography>
        {!projectHaveFinalOperations(project) ? (
          <GdButton
            onClick={() => adjustOperations()}
            label={t("finalOperations.adjust")}
            color="warning"
            isLoading={isAdjustingOperations}
          />
        ) : (
          <Stack direction="row" gap="8px">
            <GdButton
              onClick={() => adjustOperations(true)}
              label={t("finalOperations.delete")}
              color="error"
              isLoading={isAdjustingOperations}
            />
            <GdButton onClick={onClickToAddOperation} label={t("finalOperations.add")} color="primary" />
          </Stack>
        )}
      </div>
      {projectHaveFinalOperations(project) ? (
        <div className="margin-top">
          <OperationsViewer
            operations={newFinalOperations}
            sector={project.details?.sector}
            zipCode={project.client?.company?.zipCode || ""}
            editOperation={openModal}
            removeOperation={removeOperation}
            updateOperationCpe={updateOperation}
            dontTouchOperations={dontTouchOperations}
            deleteLoading={operationIsRemoving}
            replaceOperations={replaceOperations}
            addCpeCheckbox={Boolean(project.details?.cpeDuration)}
            originalOperations={project.operations as Operation[]}
            totalComputation={project.totalComputation}
          />
        </div>
      ) : (
        <Typography>{t("finalOperations.noModification")}</Typography>
      )}
      <FormEditModal
        operation={editedOperation === null ? undefined : editedOperation}
        open={modalOpen}
        onClose={closeModal}
        onUpdate={updateOperation}
        sector={project.details?.sector as Sector}
        zipCode={project.client?.company?.zipCode || ""}
        originalOperations={project.operations as Operation[]}
        isLoading={isLoading}
      />
    </>
  );
};

export default FinalOperations;
