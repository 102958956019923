import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { dateConvertToString } from "../data/dataConvertors";
import { VehicleList } from "../data/generated/graphql";

interface SubProjectProps {
  vehicleList: VehicleList;
  subProjectId: string;
}

const SubProjectAccordion: FC<SubProjectProps> = ({ vehicleList, subProjectId }) => {
  const { t } = useTranslation("project");
  return (
    <Accordion key={`${subProjectId}_${vehicleList.id}`} id={`${subProjectId}__${vehicleList.id}`}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography>
          {t("vehicleFleet.accordionSummary", {
            company: vehicleList.signer.company.name,
            count: vehicleList.vehicles?.length || 0,
          })}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <TableContainer component={Paper} variant="outlined">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t("vehicleFleet.dataGrid.enterprise")}</TableCell>
                <TableCell>{t("vehicleFleet.dataGrid.registrationNumber")}</TableCell>
                <TableCell>{t("vehicleFleet.dataGrid.isRental")}</TableCell>
                <TableCell>{t("vehicleFleet.dataGrid.proofDate")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {vehicleList.vehicles?.map((vehicle) => (
                <TableRow key={vehicle.registrationNumber}>
                  <TableCell>{vehicleList.signer.company?.name}</TableCell>
                  <TableCell>{vehicle.registrationNumber}</TableCell>
                  <TableCell>{t(`vehicleFleet.dataGrid.${JSON.stringify(vehicle.isRental)}`)}</TableCell>
                  <TableCell>{vehicle.proofDate ? dateConvertToString(vehicle.proofDate) : ""}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  );
};

export default SubProjectAccordion;
