import { Operation, ProcedureStatus, Project, Sector, Signer, ValidationStatus } from "../data/generated/graphql";
import { getFormsForSector, isNotYetApplicableForm } from "./form/FormHelper";

const defaultComputationDate = "2023-09-30T01:00:00.000Z";

export const dontTouchComputationOnProject = (project: Project): boolean =>
  Boolean(
    project.convention &&
      project.convention.status !== ProcedureStatus.Refused &&
      project.convention.status !== ProcedureStatus.Expired &&
      project.convention.status !== ProcedureStatus.Asked,
  );

export const isLatestForm = (
  operation: Operation,
  sector: Sector,
  zipCode: string,
  originalOperation?: Operation,
): boolean => {
  let form = getFormsForSector(sector, zipCode).find((f) => f.id === operation.formId);
  if (isNotYetApplicableForm(form)) form = form?.history?.[0];
  let isLatest = Boolean(
    (!form?.startDate || form?.startDate < (operation.computation?.computationDate || defaultComputationDate)) &&
      !operation.computation?.validity?.toLowerCase().includes("error"),
  );
  if (typeof originalOperation !== "undefined") {
    if (
      form?.startDate &&
      form?.startDate > (originalOperation?.computation?.computationDate || defaultComputationDate)
    ) {
      form.history?.forEach((f) => {
        if (
          (f.startDate && f.startDate < (originalOperation?.computation?.computationDate || defaultComputationDate)) ||
          !f.startDate
        ) {
          isLatest = false;
        }
      });
    }
  }
  return isLatest;
};

export const isProjectObsolete = (project: Project): boolean => {
  if (dontTouchComputationOnProject(project)) return false;

  return (
    (project.operations || []).findIndex(
      (o) => o && !isLatestForm(o, project.details?.sector || Sector.Ind, project.client?.company?.zipCode || ""),
    ) !== -1
  );
};

export const projectHaveFinalOperations = (project: Project): boolean => {
  const { finalOperations } = project;
  return typeof finalOperations !== "undefined" && finalOperations !== null && finalOperations.length > 0;
};

export const quoteIsAccepted = (project: Project): boolean => {
  const isAccepted = Boolean(project.quote && project.quote.validation?.status === ValidationStatus.Accepted);
  return isAccepted;
};

export const getEnterpriseNameWithSiret = (siret?: string, companies?: Signer[]): string => {
  const enterprise = companies?.find((e) => e.company.siret === siret);
  return enterprise?.name || "";
};
