/* eslint-disable react/no-array-index-key */
import { Close, Delete, ExpandMore, InsertDriveFile } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Modal,
  OutlinedInput,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { SnackbarKey, useSnackbar } from "notistack";
import { FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import RichTextEditor from "../common/RichTextEditor";
import { ArticlesContext } from "../data/contexts/ArticlesContext";
import { articleToArticleInput } from "../data/dataConvertors";
import { Article, ArticleInput, ArticleType, Sector } from "../data/generated/graphql";
import UploadFileButton from "../projects/documents/UploadFileButton";
import BaseCard from "../utils/BaseCard";
import GdButton from "../utils/GdButton";

interface ArticleItemProps {
  article: Article;
  onDelete: () => void;
  onModify: () => void;
  isDeleting: boolean;
}

const ArticleItem: FC<ArticleItemProps> = ({ article, onDelete, onModify, isDeleting }) => {
  const { t } = useTranslation("global");
  const { uploadAttachment, removeAttachment } = useContext(ArticlesContext);
  const [removing, setRemoving] = useState<string | null>(null);
  const deleteAttachment = async (aId: string): Promise<void> => {
    setRemoving(aId);
    await removeAttachment(article.id, aId);
    setRemoving(null);
  };
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography variant="h6">{article.title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography className="ql-editor">
          {/* eslint-disable-next-line react/no-danger */}
          <span dangerouslySetInnerHTML={{ __html: article.body }} />
        </Typography>
        <div className="big-margin-top">
          <Typography style={{ fontWeight: "bold" }}>
            {article.attachments && article.attachments.length > 0 ? t("attachmentsTwo") : t("attachmentsEmpty")}
          </Typography>
          {article.attachments && article.attachments.length > 0 ? (
            <List>
              {article.attachments.map((a) => (
                <ListItem
                  key={a.id}
                  secondaryAction={
                    removing === a.id ? (
                      <CircularProgress size={24} />
                    ) : (
                      <IconButton edge="end" onClick={() => deleteAttachment(a.id)} disabled={Boolean(removing)}>
                        <Delete />
                      </IconButton>
                    )
                  }>
                  <ListItemAvatar>
                    <Avatar>
                      <InsertDriveFile />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <a href={a.url as string} target="_blank" rel="noreferrer" className="attachment">
                        {a.name}
                      </a>
                    }
                  />
                </ListItem>
              ))}
            </List>
          ) : undefined}
        </div>
        <div className="row space-evenly margin-top">
          <UploadFileButton
            label={t("attachToArticle")}
            color="secondary"
            uploadProcess={(file: File) => uploadAttachment(article.id, file)}
            buttonId={`add-attachment-${article.id}`}
            buttonWidth="normal"
          />
          <GdButton label={t("deleteArticle")} color="inherit" onClick={onDelete} isLoading={isDeleting} />
          <GdButton label={t("modifyArticle")} onClick={onModify} />
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

interface ArticlesProps {
  type: ArticleType;
}

const Articles: FC<ArticlesProps> = ({ type }) => {
  const [updating, setUpdating] = useState(false);
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [sector, setSector] = useState("");
  const [articleId, setArticleId] = useState("");
  const [filter, setFilter] = useState("");
  const [deletingArticleId, setDeletingArticleId] = useState("");
  const [open, setOpen] = useState(false);
  const { t } = useTranslation("global");
  const { articles, updateArticle } = useContext(ArticlesContext);
  const { enqueueSnackbar } = useSnackbar();

  const finalArticles = articles
    .filter((a) => a.type === type && a.active)
    .filter(
      (a) =>
        filter.length === 0 ||
        a.title.toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
        a.body.toLowerCase().indexOf(filter.toLowerCase()) !== -1,
    );
  // const uniqueSectors = Array.from(
  //   new Set(
  //     finalArticles.map((item) => (Object.values(Sector)
  //     .includes(item.sector as Sector) ? item.sector : "Other")
  //   ),
  //   ),
  // );
  // const [activeTab, setActiveTab] = useState(0);
  // const handleTabChange = (event: React.SyntheticEvent, newValue: number): void => {
  //   setActiveTab(newValue);
  // };
  const openArticleModal = (article?: Article): void => {
    setTitle(article?.title || "");
    setBody(article?.body || "");
    setArticleId(article?.id || "");
    setSector(article?.sector || "");
    setOpen(true);
  };
  const closeArticleModal = (): void => {
    setTitle("");
    setBody("");
    setArticleId("");
    setSector("");
    setOpen(false);
  };
  const showError = (): SnackbarKey => enqueueSnackbar(t("errorWhileUpdating"), { variant: "error" });
  const updateOnServer = async (article: ArticleInput): Promise<void> => {
    setUpdating(true);
    try {
      const result = await updateArticle(article);
      if (!result) showError();
      else {
        enqueueSnackbar(t("updateSuccess"), { variant: "success" });
        closeArticleModal();
      }
    } catch (err) {
      showError();
    }
    setUpdating(false);
  };
  const saveArticle = async (): Promise<void> =>
    updateOnServer({
      id: articleId,
      title,
      body,
      type,
      active: true,
      sector,
    });
  const deleteArticle = (article: Article) => async (): Promise<void> => {
    setDeletingArticleId(article.id);
    await updateOnServer({ ...articleToArticleInput(article), active: false });
    setDeletingArticleId("");
  };
  // useEffect(() => {
  //   setActiveTab(0);
  // }, [type]);

  return (
    <BaseCard>
      <div className="row space-between margin-bottom">
        <Typography variant="h6">{t(`${type}.title`)}</Typography>
        <div className="row">
          <FormControl style={{ width: 300 }} variant="outlined">
            <InputLabel htmlFor="article-filter">{t("filterArticle")}</InputLabel>
            <OutlinedInput
              id="article-filter"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={() => setFilter("")} edge="end">
                    <Close />
                  </IconButton>
                </InputAdornment>
              }
              label={t("filterArticle")}
            />
          </FormControl>
          <div style={{ width: 32 }} />
          <GdButton label={t("addArticle")} onClick={() => openArticleModal()} />
        </div>
      </div>
      {finalArticles.length > 0 ? (
        <Stack
          direction="column"
          // gap="24px"
        >
          {/* <Tabs value={activeTab} onChange={handleTabChange} aria-label="Sector Tabs">
            {uniqueSectors.map((s, index) => (
              <Tab key={`${s}_${index}`} label={s === "Other" ? t("articleSector.other") : s} />
            ))}
          </Tabs> */}
          {/* {uniqueSectors.map((s, index) => ( */}
          {/* <div hidden={activeTab !== index} key={`${s}__${index}`}> */}
          {finalArticles
            // .filter((item) =>
            //   s === "Other" ? !Object.values(Sector).includes(item.sector as Sector) : item.sector === s,
            // )
            .map((filteredItem) => (
              <ArticleItem
                article={filteredItem}
                key={filteredItem.id}
                onDelete={deleteArticle(filteredItem)}
                onModify={() => openArticleModal(filteredItem)}
                isDeleting={deletingArticleId === filteredItem.id}
              />
            ))}
          {/* </div> */}
          {/* ))} */}
        </Stack>
      ) : (
        <Typography className="text-center margin-top">{t("noArticle")}</Typography>
      )}

      <Modal open={open} onClose={() => closeArticleModal()} className="project-modal-root">
        <Paper className="project-modal-paper">
          <Typography variant="h6" className="margin-bottom" style={{ width: 650 }}>
            {t(articleId.length > 0 ? "modify" : "add")}
          </Typography>
          <Stack direction="row" gap="8px">
            <TextField
              sx={{ width: "542px" }}
              label={t("title")}
              className="margin-bottom"
              variant="outlined"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              autoComplete="off"
            />
            <FormControl style={{ width: 100 }}>
              <InputLabel id="civ-select-sector">{t("articleSector.select")}</InputLabel>
              <Select
                labelId="civ-select-sector"
                label={t("articleSector.select")}
                variant="outlined"
                value={sector}
                onChange={(e) => setSector(e.target.value)}
                style={{ width: 100 }}>
                {Object.values(Sector).map((s) => (
                  <MenuItem value={s}>{s}</MenuItem>
                ))}
                <MenuItem value="other">{t("articleSector.other")}</MenuItem>
              </Select>
            </FormControl>
          </Stack>
          <div className="margin-bottom">
            <RichTextEditor placeholder={t("body")} body={body} setBody={setBody} />
          </div>
          <GdButton
            label={t("saveArticle")}
            onClick={saveArticle}
            isLoading={updating}
            disabled={title.length === 0 || body.length === 0}
          />
        </Paper>
      </Modal>
    </BaseCard>
  );
};

export default Articles;
