import axios from "axios";
import { createContext, FC, PropsWithChildren, useContext, useState } from "react";
import { log, Lvl } from "../../utils/log";
import { CustomDetails, TextDateConvertToNumber } from "../dataConvertors";
import {
  AdminAction,
  AdminActionType,
  Attachment,
  AttachmentType,
  Company,
  Document,
  DocumentType,
  History,
  InvoiceInfo,
  MemberStatus,
  MemberType,
  ProcedureStatus,
  Project,
  ProjectFlagsInput,
  ProjectLightFragment,
  ProjectUpdate,
  SubProject,
  useCompanyGetFromSiretQuery,
  useProjectAddViewerMutation,
  useProjectAffectObligedMutation,
  useProjectChangeUserMutation,
  useProjectCheckConventionMutation,
  useProjectForceRateMutation,
  useProjectGetAllQuery,
  useProjectGetDetailsQuery,
  useProjectGetDetailsWithProjectsListQuery,
  useProjectPrepareBillingRequestMutation,
  useProjectPrepareConventionMutation,
  useProjectRefuseConventionMutation,
  useProjectRemoveViewerMutation,
  useProjectUpdateDatesHistoryMutation,
  useProjectUpdateFlagsMutation,
  useProjectUpdateMutation,
  useProjectValidateAttachmentMutation,
  useProjectValidateManualDocumentMutation,
  useProjectValidateQuoteMutation,
  useSubProjectsGetByProjectIdQuery,
  ValidationStatus,
} from "../generated/graphql";
import { TokenContext } from "./TokenContext";

export interface ProjectsContextProps {
  projects: Project[];
  getProjects: () => Promise<boolean>;
  replaceProjects: (newProjects: Project[]) => void;
  actions: AdminAction[];
  setActions: (inActions: AdminAction[]) => void;
  getProjectDetails: (id: string) => Promise<boolean>;
  affectObliged: (projectId: string, obligedId: string) => Promise<boolean>;
  prepareConvention: (project: ProjectUpdate, conventionType: DocumentType) => Promise<boolean>;
  markActionDone: (action: AdminAction) => void;
  optimisticConventionSignature: (project: Project, refused?: boolean) => void;
  checkConvention: (projectId: string) => Promise<boolean>;
  validateQuote: (project: ProjectUpdate) => Promise<boolean>;
  refuseConvention: (projectId: string, comment: string) => Promise<boolean>;
  forceRate: (projectId: string, rate: string) => Promise<boolean>;
  validateAttachment: (
    projectId: string,
    attachmentId: string,
    accept: boolean,
    userId: string,
    comment?: string,
    invoiceInfo?: InvoiceInfo,
  ) => Promise<boolean>;
  uploadFile: (
    projectId: string,
    file: File,
    type: AttachmentType,
    concerns?: string[],
    oId?: string,
    overrideMode?: boolean,
  ) => Promise<boolean>;
  uploading: boolean;
  validateManualDocument: (
    projectId: string,
    type: AttachmentType,
    accept: boolean,
    userId: string,
    comment?: string,
    docId?: string,
  ) => Promise<boolean>;
  changeUser: (projectId: string, newUserId: string) => Promise<boolean>;
  updateProject: (project: ProjectUpdate) => Promise<boolean>;
  getCompanyFromSiret: (siret: string) => Promise<Company | null>;
  prepareBillingRequest: (projectId: string, isUserBillingRequest?: boolean) => Promise<boolean>;
  addViewer: (projectId: string, viewerId: string) => Promise<boolean>;
  removeViewer: (projectId: string, viewerId: string) => Promise<boolean>;
  updateFlags: (projectId: string, flags: ProjectFlagsInput) => Promise<boolean>;
  updateDatesHistory: (
    projectId: string,
    history: History,
    invoiceInfo: InvoiceInfo,
    quoteAmount: string,
    quoteDate: string,
    userRate: string,
    customObligedRate: string,
    billingAmount: string,
    billingDate: string,
  ) => Promise<boolean>;
  subProjects: SubProject[];
  getSubProjects: (projectId: string) => Promise<boolean>;
}

interface ProjectCache {
  projectId: string;
  lastFetch: number;
}

export interface CustomProject extends ProjectLightFragment {
  details?: CustomDetails | null | undefined;
}
const initialContext: ProjectsContextProps = {
  projects: [],
  getProjects: () => Promise.resolve(false),
  replaceProjects: () => {},
  actions: [],
  setActions: () => {},
  getProjectDetails: () => Promise.resolve(false),
  affectObliged: () => Promise.resolve(false),
  prepareConvention: () => Promise.resolve(false),
  markActionDone: () => {},
  optimisticConventionSignature: () => {},
  checkConvention: () => Promise.resolve(false),
  validateQuote: () => Promise.resolve(false),
  refuseConvention: () => Promise.resolve(false),
  forceRate: () => Promise.resolve(false),
  validateAttachment: () => Promise.resolve(false),
  uploadFile: () => Promise.resolve(false),
  uploading: false,
  validateManualDocument: () => Promise.resolve(false),
  changeUser: () => Promise.resolve(false),
  updateProject: () => Promise.resolve(false),
  getCompanyFromSiret: () => Promise.resolve(null),
  prepareBillingRequest: () => Promise.resolve(false),
  addViewer: () => Promise.resolve(false),
  removeViewer: () => Promise.resolve(false),
  updateFlags: () => Promise.resolve(false),
  updateDatesHistory: () => Promise.resolve(false),
  subProjects: [],
  getSubProjects: () => Promise.resolve(false),
};

export const ProjectsContext = createContext<ProjectsContextProps>(initialContext);

export const ProjectsProvider: FC<PropsWithChildren> = ({ children }) => {
  const { getToken } = useContext(TokenContext);
  const [projects, setProjects] = useState<Project[]>([]);
  const [projectCache, setProjectCache] = useState<ProjectCache[]>([]);
  const [actions, setActions] = useState<AdminAction[]>([]);
  const [companies, setCompanies] = useState<Record<string, Company>>({});
  const [uploading, setUploading] = useState(false);
  const { refetch } = useProjectGetAllQuery({ skip: true });
  const { refetch: detailsFetch } = useProjectGetDetailsQuery({ skip: true });
  const { refetch: detailsWithListFetch } = useProjectGetDetailsWithProjectsListQuery({ skip: true });
  const { refetch: subProjectsFetch } = useSubProjectsGetByProjectIdQuery({ skip: true });
  const [projectAffectObliged] = useProjectAffectObligedMutation();
  const [projectPrepareConvention] = useProjectPrepareConventionMutation();
  const [projectCheckConvention] = useProjectCheckConventionMutation();
  const [projectValidateQuote] = useProjectValidateQuoteMutation();
  const [projectRefuseConvention] = useProjectRefuseConventionMutation();
  const [projectForceRate] = useProjectForceRateMutation();
  const [projectValidateAttachment] = useProjectValidateAttachmentMutation();
  const [projectValidateManualDocument] = useProjectValidateManualDocumentMutation();
  const [projectChangeUser] = useProjectChangeUserMutation();
  const [projectUpdate] = useProjectUpdateMutation();
  const { refetch: companyRefetch } = useCompanyGetFromSiretQuery({ skip: true });
  const [projectPrepareBillingRequest] = useProjectPrepareBillingRequestMutation();
  const [projectAddViewer] = useProjectAddViewerMutation();
  const [projectRemoveViewer] = useProjectRemoveViewerMutation();
  const [projectUpdateFlags] = useProjectUpdateFlagsMutation();
  const [projectUpdateDatesHistory] = useProjectUpdateDatesHistoryMutation();
  const [subProjects, setSubProjects] = useState<SubProject[]>([]);

  const projectToCustomProject = (project: ProjectLightFragment | Project): CustomProject => {
    const customProject: CustomProject = { ...project };
    if (customProject.details?.startDate && customProject.details?.endDate) {
      customProject.details = {
        ...customProject.details,
        startDateNum: TextDateConvertToNumber(customProject.details.startDate),
        endDateNum: TextDateConvertToNumber(customProject.details.endDate),
      };
    }
    return customProject;
  };

  const updateCache = (project: Project, inProjects?: CustomProject[]): void => {
    let found = false;
    const newProjects = (inProjects || projects).map((u) => {
      if (u.id === project.id) return projectToCustomProject(project);
      return u;
    });
    setProjects(newProjects);
    const newProjectCache = projectCache.map((pc) => {
      if (pc.projectId === project.id) {
        found = true;
        return { ...pc, lastFetch: new Date().getTime() };
      }
      return pc;
    });
    if (!found) newProjectCache.push({ projectId: project.id, lastFetch: new Date().getTime() });
    setProjectCache(newProjectCache);
  };

  const replaceProjects = (newProjects: ProjectLightFragment[]): CustomProject[] => {
    const tempProjects: CustomProject[] = newProjects.map((tp) => {
      // Don't replace project details with project light if it has been fetched
      const cache = projectCache.find((cp) => cp.projectId === tp.id);
      if (cache) return projects.find((p) => p.id === tp.id) || tp;
      return projectToCustomProject(tp);
    });
    setProjects(tempProjects);
    return tempProjects;
  };

  const getProjects = async (): Promise<boolean> => {
    const result = await refetch();
    if (result && result.data) {
      replaceProjects(result.data.projectGetAll || []);
      return true;
    }
    return false;
  };

  const getProjectDetails = async (id: string, forceCache?: boolean): Promise<boolean> => {
    const { lastFetch } = projectCache.find((pc) => pc.projectId === id) || { lastFetch: 0 };
    if (lastFetch > new Date().getTime() - 5 * 60 * 1000 && forceCache !== true) {
      return true;
    }
    try {
      if (projects.length === 0) {
        const tmpResult = await detailsWithListFetch({ id });
        if (tmpResult && tmpResult.data) {
          const tempProjects = replaceProjects(tmpResult.data?.projectGetAll || []);
          const newProject = tmpResult.data?.projectGetById;
          if (newProject) {
            updateCache(newProject as Project, tempProjects);
            return true;
          }
        }
      }
      const result = await detailsFetch({ id });
      if (result && result.data) {
        const newProject = result.data.projectGetById;
        if (newProject) {
          updateCache(newProject as Project);
          return true;
        }
      }
    } catch (err) {
      log(err, Lvl.ERROR);
    }
    return false;
  };

  const getSubProjects = async (projectId: string): Promise<boolean> => {
    try {
      const result = await subProjectsFetch({ projectId });
      if (result && result.data) {
        setSubProjects(result.data.subProjectsGetByProjectId as SubProject[]);
        return true;
      }
    } catch (err) {
      log(err, Lvl.ERROR);
    }
    return false;
  };
  const affectObliged = async (projectId: string, obligedId: string): Promise<boolean> => {
    try {
      const result = await projectAffectObliged({ variables: { projectId, obligedId } });
      if (result && result.data) {
        const newLocalProject = result.data.projectAffectObliged as Project;
        updateCache(newLocalProject);
        return true;
      }
      return false;
    } catch (err) {
      log("Affect obliged failed", Lvl.ERROR, err);
      return false;
    }
  };

  const prepareConvention = async (project: ProjectUpdate, conventionType: DocumentType): Promise<boolean> => {
    try {
      const result = await projectPrepareConvention({ variables: { project, conventionType } });
      if (result && result.data) {
        const newLocalProject = result.data.projectPrepareConvention as Project;
        updateCache(newLocalProject);
        return true;
      }
      return false;
    } catch (err) {
      log("Prepare convention failed", Lvl.ERROR, err);
      return false;
    }
  };

  const checkConvention = async (projectId: string): Promise<boolean> => {
    try {
      const result = await projectCheckConvention({ variables: { projectId } });
      if (result && result.data) {
        const newLocalProject = result.data.projectCheckConvention as Project;
        updateCache(newLocalProject);
        return true;
      }
      return false;
    } catch (err) {
      log("Check convention failed", Lvl.ERROR, err);
      return false;
    }
  };

  const markActionDone = (action: AdminAction): void => {
    const actionIndex = actions.findIndex((a) => a.projectId === action.projectId && a.type === action.type);
    if (actionIndex !== -1) {
      setActions([...actions.slice(0, actionIndex), ...actions.slice(actionIndex + 1)]);
    }
  };

  const validateQuote = async (project: ProjectUpdate): Promise<boolean> => {
    try {
      const result = await projectValidateQuote({ variables: { project } });
      if (result && result.data) {
        const newLocalProject = result.data.projectValidateQuote as Project;
        updateCache(newLocalProject);
        markActionDone({ projectId: project.id, type: AdminActionType.ValidateQuote });
        return true;
      }
      return false;
    } catch (err) {
      log("Validate quote failed", Lvl.ERROR, err);
      return false;
    }
  };

  const optimisticConventionSignature = (project: Project, refused?: boolean): void => {
    updateCache({
      ...project,
      convention: {
        ...project?.convention,
        details: {
          ...project?.convention?.details,
          members: project.convention?.details?.members?.map((m) => {
            if (m.type !== MemberType.Admin) return m;
            return {
              ...m,
              status: refused ? MemberStatus.Refused : MemberStatus.Done,
            };
          }),
        },
        // eslint-disable-next-line no-nested-ternary
        status: refused
          ? ProcedureStatus.Refused
          : project?.convention?.details?.members
              ?.filter((m) => m.type !== MemberType.Admin)
              .findIndex((m) => m.status !== MemberStatus.Done) === -1
          ? ProcedureStatus.Finished
          : project?.convention?.status,
      },
    });
    markActionDone({ projectId: project.id, type: AdminActionType.SignConvention });
  };

  const refuseConvention = async (projectId: string, comment: string): Promise<boolean> => {
    try {
      const result = await projectRefuseConvention({ variables: { projectId, comment } });
      if (result && result.data) {
        const newLocalProject = result.data.projectRefuseConvention as Project;
        updateCache(newLocalProject);
        return true;
      }
      return false;
    } catch (err) {
      log("Refuse convention failed", Lvl.ERROR, err);
      return false;
    }
  };

  const forceRate = async (projectId: string, rate: string): Promise<boolean> => {
    try {
      const result = await projectForceRate({ variables: { projectId, rate } });
      if (result && result.data) {
        const newLocalProject = result.data.projectForceRate as Project;
        updateCache(newLocalProject);
        return true;
      }
      return false;
    } catch (err) {
      log("Force rate failed", Lvl.ERROR, err);
      return false;
    }
  };

  const changeUser = async (projectId: string, newUserId: string): Promise<boolean> => {
    try {
      const result = await projectChangeUser({ variables: { projectId, newUserId } });
      if (result && result.data) {
        const newLocalProject = result.data.projectChangeUser as Project;
        updateCache(newLocalProject);
        return true;
      }
      return false;
    } catch (err) {
      log("Change user failed", Lvl.ERROR, err);
      return false;
    }
  };

  const validateAttachment = async (
    projectId: string,
    attachmentId: string,
    accept: boolean,
    userId: string,
    comment?: string,
    invoiceInfo?: InvoiceInfo,
  ): Promise<boolean> => {
    try {
      const result = await projectValidateAttachment({
        variables: {
          projectId,
          attachmentId,
          validation: {
            status: accept ? ValidationStatus.Accepted : ValidationStatus.Rejected,
            comment,
            userId,
            date: new Date().toISOString(),
          },
          invoiceInfo,
        },
      });
      if (result && result.data) {
        const newLocalProject = result.data.projectValidateAttachment as Project;
        updateCache(newLocalProject);
        return true;
      }
      return false;
    } catch (err) {
      log("Validate attachment failed", Lvl.ERROR, err);
      return false;
    }
  };
  const uploadFile = async (
    projectId: string,
    file: File,
    type: AttachmentType,
    concerns?: string[],
    oId?: string,
    overrideMode?: boolean,
  ): Promise<boolean> => {
    const token = await getToken();
    setUploading(true);
    try {
      const data = new FormData();
      data.append("file", file);
      const result = await axios({
        url: `${process.env.REACT_APP_UPLOAD_API_URL || ""}${
          process.env.REACT_APP_UPLOAD_API_URL?.indexOf("?") === -1 ? "?" : "&"
        }projectId=${projectId}&type=${type}&name=${file.name}${concerns ? `&concerns=${concerns.join(",")}` : ""}${
          oId ? `&operationId=${oId}` : ""
        }${overrideMode ? "&overrideMode=true" : ""}`,
        method: "POST",
        data,
        headers: {
          authorization: `Bearer ${token}`,
          "content-type": "multipart/form-data",
        },
      });
      if (result.status !== 200) {
        log("Error while uploading", Lvl.ERROR, result);
        return false;
      }
      if (type === AttachmentType.Convention) {
        await getProjectDetails(projectId, true);
      } else {
        const concernedProject = projects.find((p) => p.id === projectId);
        if (concernedProject) {
          if (type === AttachmentType.SwornStatementModel || type === AttachmentType.SwornStatement) {
            let found = false;
            const newSwornStatements: Document[] =
              concernedProject.swornStatements?.map((s) => {
                if (s.id === result.data.id) {
                  found = true;
                  return result.data;
                }
                return s;
              }) || [];
            if (!found) newSwornStatements.push(result.data);
            updateCache({ ...concernedProject, swornStatements: newSwornStatements });
          } else if (type === AttachmentType.Other) {
            let found = false;
            const newAttachments: Attachment[] =
              concernedProject.attachments?.map((a) => {
                if (a.id === result.data.id) {
                  found = true;
                  return result.data;
                }
                return a;
              }) || [];
            if (!found) newAttachments.push(result.data);
            updateCache({ ...concernedProject, attachments: newAttachments });
          } else if (type === AttachmentType.Quote) {
            updateCache({ ...concernedProject, quote: result.data });
          } else if (type === AttachmentType.Billing) {
            updateCache({ ...concernedProject, billing: result.data });
          }
        }
      }
      return true;
    } catch (err) {
      log("Error while uploading", Lvl.ERROR, err);
      return false;
    } finally {
      setUploading(false);
    }
  };

  const validateManualDocument = async (
    projectId: string,
    type: AttachmentType,
    accept: boolean,
    userId: string,
    comment?: string,
    docId?: string,
  ): Promise<boolean> => {
    try {
      const result = await projectValidateManualDocument({
        variables: {
          projectId,
          type,
          validation: {
            status: accept ? ValidationStatus.Accepted : ValidationStatus.Rejected,
            comment,
            userId,
            date: new Date().toISOString(),
          },
          docId,
        },
      });
      if (result && result.data) {
        const newLocalProject = result.data.projectValidateManualDocument as Project;
        updateCache(newLocalProject);
        return true;
      }
      return false;
    } catch (err) {
      log("Validate manual document failed", Lvl.ERROR, err);
      return false;
    }
  };

  const updateProject = async (project: ProjectUpdate): Promise<boolean> => {
    const result = await projectUpdate({ variables: { project } });

    if (result && result.data) {
      const newLocalProject = result.data.projectUpdate as Project;
      updateCache(newLocalProject);
      return true;
    }
    return false;
  };

  const getCompanyFromSiret = async (siret: string): Promise<Company | null> => {
    const localCompany = companies[siret];
    if (!localCompany) {
      // Get company from siret
      const result = await companyRefetch({ siret });
      if (result && result.data) {
        const newLocalCompany = result.data.companyGetFromSiret as Company;
        setCompanies({ ...companies, [newLocalCompany.siret]: newLocalCompany });
        return newLocalCompany;
      }
    }
    return new Promise((resolve) => setTimeout(() => resolve(localCompany), 500));
  };

  const prepareBillingRequest = async (projectId: string, isUserBillingRequest?: boolean): Promise<boolean> => {
    const result = await projectPrepareBillingRequest({ variables: { projectId, isUserBillingRequest } });
    if (result && result.data) {
      const newLocalProject = result.data.projectPrepareBillingRequest as Project;
      updateCache(newLocalProject);
      return true;
    }
    return false;
  };

  const addViewer = async (projectId: string, viewerId: string): Promise<boolean> => {
    try {
      const result = await projectAddViewer({ variables: { projectId, viewerId } });
      if (result && result.data) {
        const newLocalProject = result.data.projectAddViewer as Project;
        updateCache(newLocalProject);
        return true;
      }
      return false;
    } catch (err) {
      log("Add viewer failed", Lvl.ERROR, err);
      return false;
    }
  };

  const removeViewer = async (projectId: string, viewerId: string): Promise<boolean> => {
    try {
      const result = await projectRemoveViewer({ variables: { projectId, viewerId } });
      if (result && result.data) {
        const newLocalProject = result.data.projectRemoveViewer as Project;
        updateCache(newLocalProject);
        return true;
      }
      return false;
    } catch (err) {
      log("Remove viewer failed", Lvl.ERROR, err);
      return false;
    }
  };

  const updateFlags = async (projectId: string, flags: ProjectFlagsInput): Promise<boolean> => {
    try {
      const result = await projectUpdateFlags({ variables: { projectId, flags } });
      if (result && result.data) {
        const newLocalProject = result.data.projectUpdateFlags as Project;
        updateCache(newLocalProject);
        return true;
      }
      return false;
    } catch (err) {
      log("Update flags failed", Lvl.ERROR, err);
      return false;
    }
  };

  const updateDatesHistory = async (
    projectId: string,
    history: History,
    invoiceInfo: InvoiceInfo,
    quoteAmount: string,
    quoteDate: string,
    userRate: string,
    customObligedRate: string,
    billingAmount: string,
    billingDate: string,
  ): Promise<boolean> => {
    try {
      const result = await projectUpdateDatesHistory({
        variables: {
          projectId,
          history,
          invoiceInfo,
          quoteAmount,
          quoteDate,
          userRate,
          customObligedRate,
          billingAmount,
          billingDate,
        },
      });
      if (result && result.data) {
        const newLocalProject = result.data.projectUpdateDatesHistory as Project;
        updateCache(newLocalProject);
        return true;
      }
      return false;
    } catch (err) {
      log("Update flags failed", Lvl.ERROR, err);
      return false;
    }
  };

  return (
    <ProjectsContext.Provider
      value={{
        projects,
        getProjects,
        replaceProjects,
        actions,
        setActions,
        getProjectDetails,
        affectObliged,
        prepareConvention,
        markActionDone,
        optimisticConventionSignature,
        checkConvention,
        validateQuote,
        refuseConvention,
        forceRate,
        validateAttachment,
        uploading,
        uploadFile,
        validateManualDocument,
        changeUser,
        updateProject,
        getCompanyFromSiret,
        prepareBillingRequest,
        addViewer,
        removeViewer,
        updateFlags,
        updateDatesHistory,
        getSubProjects,
        subProjects,
      }}>
      {children}
    </ProjectsContext.Provider>
  );
};
